<template>
  <div class="contact">
    <MapContact></MapContact>

    <h2><span class="orange">Nous</span> contacter</h2>

    <form>
      <h3>Contactez nous !</h3>
      <div>
        <input type="email" id="mail" name="user_mail" placeholder="Email" required>
      </div>

      <div>
        <input type="text" id="objet" name="objet" placeholder="Objet" required>
      </div>

      <div>
        <textarea id="msg" name="msg" placeholder="Votre message" rows="10" required></textarea>
      </div>

      <vue-hcaptcha sitekey="**Your sitekey here**"></vue-hcaptcha>

      <div class="btnSubmit" @click="submit()">Envoyer</div>
    </form>

  </div>
</template>

<style scoped>

form{
  position: absolute;
  width: 40%;
  margin-top: 50px;
  text-align: center;
  padding: 20px 0;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  font-family: "jura";
  right: 110px;
}

form div{
  width: 65%;
  margin: auto;
  overflow: visible;
}

form div input, form div textarea{
  color: white;
  width: 100%;
  margin: 20px auto;
  resize:none;
  border: 1px white solid;
  border-radius: 5px;
  transition: .3s;
  background: none;
  padding: 4px;
}

form div input:focus, form div textarea:focus{
  border: 1px rgb(30, 178, 236) solid;
}

::placeholder {
  color: rgb(255, 255, 255);
}

.btnSubmit{
  color: white;
  text-decoration: none;
  padding: 10px;
  border: 1px white solid;
  border-radius: 50px;
  font-size: 14px;
  display: block;
  width: 110px;
  text-align: center;
  transition: .2s;
  cursor: pointer;
}

.btnSubmit:hover{
  color:rgb(30, 178, 236);
  border: 1px rgb(30, 178, 236) solid;
}

@media screen and (max-width: 1024px) {
  form{
    width: 100%;
    right: initial;
  }
}
</style>

<script>
import MapContact from "../components/MapContact"
import axios from 'axios'

export default {
  name: 'Contact',
  components: { MapContact},
  data(){},
  mounted () {
    window.scrollTo(0, 0)
  },
  methods: {
    submit(){
      const mail = document.getElementById('mail').value;
      const objet = document.getElementById('objet').value;
      const msg = document.getElementById('msg').value;

      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        this.emailValide = true;
      }else{
        document.getElementById("mail").setCustomValidity("Champ invalide");
        this.emailValide = false;
      }
      if (/^([a-zA-Z]){2,15}$/.test(objet)) {
        this.objetValide = true;
      }else{
        document.getElementById("objet").setCustomValidity("Champ invalide");
        this.objetValide = false;
      }
      if (msg != "") {
        this.msgValide = true;
      }else{
        document.getElementById("msg").setCustomValidity("Champ invalide");
        this.msgValide = false;
      }


      if (this.emailValide == true && this.objetValide == true && this.msgValide == true) {
        const data = {
          mail,
          objet,
          msg
        };

        axios
        .post('https://ordibessin.fr/api/email' /*'http://localhost:5000/api/email'*/, data)
        .then(
          response => (console.log(response)),
          alert("Votre message a bien été envoyé !"),
          this.$router.push({ path: '/' })
        )
        .catch(function (error) {
          alert(error)
          console.log(error)
        });

      }
    }
  },
}
</script>