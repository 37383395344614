<template>
    <div id='mapT_E_B'>

    </div>
</template>

<script>
import mapboxgl from 'mapbox-gl'

export default {
  components: {},
  mounted(){

      mapboxgl.accessToken = 'pk.eyJ1IjoiaWtpMTMzNyIsImEiOiJjazFsMnJpNGIwMWI5M2lueXVydXB1a3g2In0.8bSCj5lTGb4K_KHagX4Caw';
      const map = new mapboxgl.Map({
          container: 'mapT_E_B',
          style: 'mapbox://styles/iki1337/ck1l35mog0ihy1cn73nndy9ts',
          zoom:10.89,
          center : {lon : -0.7789999999999964, lat : 49.2962},
        });

        var geojson = {
            type: 'FeatureCollection',
            features: [{
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [-0.7789999999999964, 49.2962]
                    },
                properties: {
                    title: 'Mapbox',
                    description: 'Tour-En-Bessin'
                }
            }]
        };

        // add markers to map
        geojson.features.forEach(function(marker) {
            // create a HTML element for each feature
            var el = document.createElement('div');
            el.className = 'marker';
            // make a marker for each feature and add to the map
            new mapboxgl.Marker(el)
            .setLngLat(marker.geometry.coordinates)
            .addTo(map);
        });

    }
}



</script>

<style>
#mapT_E_B {
  position: fixed;
  min-width: 100%;
  min-height: 100vh;
  z-index: -100;
}

.marker {
  background-image: url('../assets/mapbox-icon.png');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  opacity: 1;
  position: absolute;
  padding: 12px;
  border: red solid 2px;
}

.mapboxgl-popup {
  max-width: 200px;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: 'jura';
}


</style>